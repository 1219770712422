import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HrRules } from 'src/app/model/menu/hrrules/hr-rules.model';
import { CommonConstants } from 'src/app/utility/common-constants';

@Injectable({
  providedIn: 'root'
})
export class RulesService {
 
  strUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.hrRules;
  
  constructor(private http:HttpClient) { }
  
  loadRuleGroup(obj:any){
    return this.http.post(this.strUrl+'/rulesgroup',obj);
  }
  loadRulesDetails(obj:HrRules){
    return this.http.post(this.strUrl,obj);
  }
  loadRulesDetailsByRuleName(obj:any){
    return this.http.post(this.strUrl+'/ruleName',obj);
  }
  getMaxRuleId(obj:any){
    return this.http.post(this.strUrl+'/maxId',obj);
  }
  createHrRules(obj:HrRules){
    if(obj!=undefined){
        if(obj.ACTIVEFLAG==true){
          obj.ACTIVEFLAG='Y';
        }else{
          obj.ACTIVEFLAG='N';
        }
        if(obj.RELATEDEMPFLAG==true){
           obj.RELATEDEMPFLAG='Y';
        }else{
          obj.RELATEDEMPFLAG='N';
        }
    }
    return this.http.post(this.strUrl+'/'+CommonConstants.add,obj);
  }
  updateHrRules(obj:HrRules,strToAddress:string){
    if(obj!=undefined){
      if(obj.ACTIVEFLAG==true){
        obj.ACTIVEFLAG='Y';
      }else{
        obj.ACTIVEFLAG='N';
      }
      if(obj.RELATEDEMPFLAG==true){
         obj.RELATEDEMPFLAG='Y';
      }else{
        obj.RELATEDEMPFLAG='N';
      }
      if(strToAddress!=undefined){
        obj.TOADDRESS=strToAddress;
      }
   }
    return this.http.post(this.strUrl+'/'+CommonConstants.edit,obj);
  }
}
