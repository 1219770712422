import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};
@Injectable({
  providedIn: 'root'
})
export class DependentDetailsService {

  baseUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.EmployeeDependentDetails;
  constructor(private http:HttpClient) { 
  }

  getRelationType(dependentDetailsMdel){
    debugger 
    return this.http.post(this.baseUrl+'/'+'loadRelation',dependentDetailsMdel);
  }

  loadGridData(dependentDetailsMdel){
    return this.http.post(this.baseUrl,dependentDetailsMdel);
  }

  getMaxSerialNo(dependentDetailsMdel){
    return this.http.post(this.baseUrl+'/'+"getMaxSno",dependentDetailsMdel)
  }
  
  save(dependentDetailsMdel){
    return this.http.post(this.baseUrl+'/'+CommonConstants.add,dependentDetailsMdel)
  }

  update(dependentDetailsMdel){
    return this.http.post(this.baseUrl+'/'+CommonConstants.edit,dependentDetailsMdel)
  }

 
}
