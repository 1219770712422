import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';
import { ThirdPartyConsultant } from 'src/app/model/menu/marketing/third-party-consultant.model';
import { MarketingQualificationDetails } from 'src/app/model/menu/marketing/marketing-qualification-details.class';
import { MarketingCertificateDetails } from 'src/app/model/menu/marketing/marketing-certificate-details.class';
import { EmployeeMain } from 'src/app/model/menu/hr/hr-employee/employee-main.model';
import { EmployeeGeneralDetails } from 'src/app/model/menu/hr/hr-employee/employee-general-details.model';
import { EmployeeContactDetails } from 'src/app/model/menu/reports/employee/employee-contact-details.model';
import { ContactDetails } from 'src/app/model/menu/hr/hr-employee/contact-details.model';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyConsultantService {
      strUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.thirdPartyConsultant;
      constructor(private http:HttpClient) { }
      loadThirdpartyConsultantGrid(thirdPartyConsultant:ThirdPartyConsultant){
        return this.http.post(this.strUrl,thirdPartyConsultant);
      }
      loadImmigrationDropdown(thirdPartyConsultant:ThirdPartyConsultant){
        return this.http.post(this.strUrl+'/immigration',thirdPartyConsultant);
      }
      loadVendorListDropdown(thirdPartyConsultant:ThirdPartyConsultant){
        return this.http.post(this.strUrl+'/vendor',thirdPartyConsultant);
      }
      getMaxEmployeeIdValue(thirdPartyConsultant:ThirdPartyConsultant){
        return this.http.post(this.strUrl+'/empid',thirdPartyConsultant);
      }
      loadQualificationList(marketingQualificationDetails:MarketingQualificationDetails){
        return this.http.post(this.strUrl+'/qualification',marketingQualificationDetails);
      }
      loadQualificationTypeList(marketingQualificationDetails:MarketingQualificationDetails){
        return this.http.post(this.strUrl+'/qualificationtype',marketingQualificationDetails);
      }
      loadQualificationDetailsGrid(marketingQualificationDetails:MarketingQualificationDetails){
        return this.http.post(this.strUrl+'/marketingqualification',marketingQualificationDetails);
      }
      maxSerialNumberByEmployeeId(marketingQualificationDetails:MarketingQualificationDetails){
        return this.http.post(this.strUrl+'/marketingqualification/serialno',marketingQualificationDetails);
      }
      createQualificationDetails(marketingQualificationDetails:MarketingQualificationDetails){
        return this.http.post(this.strUrl+'/'+CommonConstants.add+'/marketingqualification',marketingQualificationDetails);
      }
      editQualificationDetails(marketingQualificationDetails:MarketingQualificationDetails){
        return this.http.post(this.strUrl+'/'+CommonConstants.edit+'/marketingqualification',marketingQualificationDetails);
      }
      loadcertificationDetailsGrid(marketingCertificateDetails:MarketingCertificateDetails){
        return this.http.post(this.strUrl+'/marketingcertification',marketingCertificateDetails);
      }
      createCertificate(marketingCertificateDetails:MarketingCertificateDetails){
        return this.http.post(this.strUrl+'/'+CommonConstants.add+'/marketingcertification',marketingCertificateDetails);
      }
      editCertificate(marketingCertificateDetails:MarketingCertificateDetails){
        return this.http.post(this.strUrl+'/'+CommonConstants.edit+'/marketingcertification',marketingCertificateDetails);
      }

    createEmployee(obj:EmployeeMain){
        debugger;
      return this.http.post(CommonConstants.baseUrl+"/createEmployee",obj);
    }
    insertEmployeeGeneralDetails(obj:EmployeeGeneralDetails){
      debugger;
    return this.http.post(CommonConstants.baseUrl+"/insertEmployeeGeneralDetails",obj);
    }
    editEmployee(obj:EmployeeMain){
      return this.http.post(this.strUrl+"/editemployee",obj);
    }
    editEmployeeGeneralDetails(obj:EmployeeGeneralDetails){
      debugger;
    return this.http.post(this.strUrl+"/editEmployeeGeneralDetails",obj);
    }
    editEmployeeContactDetails(obj:ContactDetails){
      debugger;
    return this.http.post(this.strUrl+"/editContactDetails",obj);
    }
    

    uploadResume( formData:any,employeeId:any){
      debugger;
      console.log(formData+" "+employeeId);
      return this.http.post(CommonConstants.baseUrl+"/resumeupload/"+employeeId,formData);
    }
    downloadResume(){
      debugger;
      return this.http.get(CommonConstants.baseUrl+"/downloadresume");
    }

    deleteEmployee(obj:any){
      return this.http.delete(CommonConstants.baseUrl+'/deleteEmployee',obj);
    }
    deleteEmployeeFromGeneralDetails(obj:any){
      return this.http.delete(CommonConstants.baseUrl+'/deleteFromEmpGeneralDetails',obj);
    }

}
