import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';
@Injectable({
  providedIn: 'root'
})
export class EnterTimeService {
  baseUrl: string = CommonConstants.baseUrl+'/'+CommonConstants.myTimesheetEnterTime;

  constructor(private http:HttpClient) { }

  loadGrid(obj){
    debugger
    return this.http.post(this.baseUrl,obj);
  }

  // loadonlyApproved(obj){
  //   debugger
  //   return this.http.post(this.baseUrl+'/loadonlyApproved',obj);
  // }
  

  loadAlltraempimmtimeData(obj){
    debugger
    return this.http.post(this.baseUrl+"/load_alltraempimmtimeData",obj);
  }


  getHolidaysDetails(obj){
    return this.http.post(this.baseUrl+'/'+'getHolidaysDetails',obj);
  }

  // checkDuplicateDate(obj){
  //   return this.http.post(this.baseUrl+'/'+'checkDuplicateDate',obj);
  // }
  loadProjects(obj){
    return this.http.post(this.baseUrl+'/'+'loadProject',obj);
  }

  save(obj){
    return this.http.post(this.baseUrl+'/'+CommonConstants.add,obj);
  }

  delete(obj){
    return this.http.post(this.baseUrl+'/'+CommonConstants.delete,obj);
  }

  getSubgroupDetails(obj){
    return this.http.post(this.baseUrl+'/getSubgroupDetails',obj);
  }


  getEmailId(obj){
    return this.http.post(this.baseUrl+'/getConsultantEmail',obj);
  }

  getManagerDetails(obj){
    return this.http.post(this.baseUrl+'/getManagerOfConsultant',obj);
  }

  getConsultantName(obj){
    return this.http.post(this.baseUrl+'/getConsultantName',obj);
  }

  loadGridOnBasisOfCheckbox(obj){
    console.log(obj);
    return this.http.post(this.baseUrl+'/loadGridOnBasisOfCheckbox/'+obj,obj);
  }
 
}
