import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  baseUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.MarketingProjects;
  constructor(private http:HttpClient) { }

  getprojectMailAlert(operation:string){
    return this.http.get(CommonConstants.baseUrl+'/newProjectMailAlerts/'+operation)
  }

  loadProjectGrid(obj){
    return this.http.post(this.baseUrl+'/'+'loadProjectGrid',obj)
  }

  projectStatus(obj){
    return this.http.post(this.baseUrl+'/'+'projectStatus',obj)
  }

  projectType(obj){
    return this.http.post(this.baseUrl+'/'+'projectType',obj)
  }

  projectBillingCycle(obj){
    return this.http.post(this.baseUrl+'/'+'billingCycle',obj)
  }
  projectHours(obj){
    return this.http.post(this.baseUrl+'/'+'projectHours',obj)
  }

  projectMode(obj){
    return this.http.post(this.baseUrl+'/'+'projectMode',obj)
  }

  // placedby(obj){
  //   return this.http.post(this.baseUrl+'/'+'placedby',obj)
  // }

  getMaxProjectId(obj){
    return this.http.post(this.baseUrl+'/'+'getMaxProjectId',obj) 
  }
  saveProject(obj){
    return this.http.post(this.baseUrl+'/'+CommonConstants.add,obj)
  }
  editProject(obj){
    return this.http.post(this.baseUrl+'/'+CommonConstants.edit,obj)
  }

  updateOnBenchWeeks(obj){
    return this.http.post(this.baseUrl+'/onBenchWeeks/'+CommonConstants.edit,obj)
  }

   getVisaStatus(obj){
     return this.http.post(this.baseUrl+'/getVisaStatus',obj)
   }

   getEmployeeDetails(obj){
    return this.http.post(this.baseUrl+'/getEmployeeDetails',obj)
   }


   loadInactiveEmployee(obj){
    return this.http.post(this.baseUrl+'/loadInactiveEmployee',obj)
  }




  //Billing Related Services
  gerCurrency(obj){
    return this.http.post(this.baseUrl+'/billing/'+'currency',obj)
  }

  getProjectPerDiem(obj){
    return this.http.post(this.baseUrl+'/billing/'+'projectPerDiem',obj)
  }

  getRatePer(obj){
    return this.http.post(this.baseUrl+'/billing/'+'ratePer',obj) 
  }

  loadBillingGrid(obj){
    
    return this.http.post(this.baseUrl+'/billing/'+'loadBillingGrid',obj) 
  }

  getMaxBillRecNo(obj){
    return this.http.post(this.baseUrl+'/billing/'+'getMaxBillRecNo',obj) 
  }
 
  billsave(obj){
    return this.http.post(this.baseUrl+'/billing/'+CommonConstants.add,obj) 
  }

  billUpdate(obj){
    return this.http.post(this.baseUrl+'/billing/'+CommonConstants.edit,obj) 
  }
 






  //client related service
  loadClient(obj){
    debugger
    return this.http.post(this.baseUrl+'/client/'+'clientList',obj) 
  }

  loadClientType(obj){
    
    return this.http.post(this.baseUrl+'/client/'+'clientType',obj) 
  }
  loadClientGrid(obj){
    return this.http.post(this.baseUrl+'/client/'+'loadClientGrid',obj) 
  }

  getMaxClientSno(obj){
    return this.http.post(this.baseUrl+'/client/'+'getMaxClientSno',obj) 
  }
  loadNewClientGrid(obj){
    return this.http.post(this.baseUrl+'/client/'+'loadNewClientGrid',obj) 
  }
  getDuplicateClientRecord(obj){
    return this.http.post(this.baseUrl+'/client/'+'getDuplicateClientRecord',obj) 
  }
  saveClient(obj){
    
    return this.http.post(this.baseUrl+'/client/'+CommonConstants.add,obj) 
  }
   editClient(obj){
     debugger
    return this.http.post(this.baseUrl+'/client/'+CommonConstants.edit,obj) 
  }

  editClient1(obj){
   return this.http.post(this.baseUrl+'/client/'+CommonConstants.edit+"1",obj) 
 }








  //Project Location related service
  loadProjectLocationGrid(obj){
     return this.http.post(this.baseUrl+'/location/'+'loadProjectLocationGrid',obj);
  }

  projectContactType(obj){
     return this.http.post(this.baseUrl+'/location/'+'projectContactType',obj);
  }


  getMaxSlno(obj){
    return this.http.post(this.baseUrl+'/location/'+'maxsno',obj);
  }

  getClientID(obj){
    return this.http.post(this.baseUrl+'/location/'+'getClientId',obj);
  }

  saveProjectLocation(obj){
    return this.http.post(this.baseUrl+'/location/'+CommonConstants.add,obj);
  }

  editProjectLocation(obj){
    return this.http.post(this.baseUrl+'/location/'+CommonConstants.edit,obj);
  }

 

  

  

  

}
