export class ContactDetails {
    //CONSULTANTID:string='VINE0419';
    CONSULTANTID:string;
    COMPANYCODE:number;
    MOBILENUMBER:string;
    INDIACONTACTNO:string;
    HOUSEPHONENUMBER:string;
    FAXNUMBER:string;
    EMAILID1:string;
    EMAILID2:string;
    WORKPHONENUMBER:string;
    PHONEEXTENSION:string;
    emergency_name1:string;
    Relationship1:string;
    Address1:string;
    Phonenumber1:string;
    emergency_name2:string;
    Relationship2:string;
    Address2:string;
    Phonenumber2:string;
    emergency_name3:string;
    Relationship3:string;
    Address3:string;
    Phonenumber3:string;
    grpentityid:number;
    subgrpentityid:number; 
    CREATEDUSERID=sessionStorage.getItem("logInuser"); 
    MODIFIEDUSERID=sessionStorage.getItem("logInuser");
    CREATEDDT:any;
    MODIFIEDDT:any;
}
