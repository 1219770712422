
export class Client{
  
  CLIENTID:number;
  CLIENTNAME:string;
  CLIENTTYPEID:number;
  CLIENTINDUSTRYTYPEID:number;
  ISDIRECTCLIENT:string;
  PAYMENTTERMSCODE:number;
  InvoiceTypecode:number;
  ACTIVEINACTIVEFLAG:string;
  Notes:string;
  STATUS:string='Y';
  grpentityid:number=Number(sessionStorage.getItem("entityId"));
  subgrpentityid:number;
  CREATEDUSERID=sessionStorage.getItem("logInuser");;
  CREATEDDT=new Date();
  MODIFIEDUSERID=sessionStorage.getItem("logInuser");;
  MODIFIEDDT=new Date();
  subgrpentityname:string;
}