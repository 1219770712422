import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    //'userid':'1',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};
@Injectable({
  providedIn: 'root'
})
export class ManualExpenseService {

  baseUrl: string = CommonConstants.baseUrl+'/'+CommonConstants.manualExpense;
  
 
  values :any;
 constructor(private http: HttpClient) { 
  
 }

  // getEmployeeList(obj){
    
  //   return this.http.post(this.baseUrl+'/'+'getEmployeeList',obj)

  // }
  loadDescription(obj){
    return this.http.post(this.baseUrl+'/'+'loadDescription',obj)
  }

  loadGridData(obj){
    return this.http.post(this.baseUrl,obj)
  }
  loadPayrollId(obj){
    return this.http.post(this.baseUrl+'/'+'loadPayrollId',obj)
  }

  save(obj){
    debugger
    return this.http.post(this.baseUrl+'/'+CommonConstants.add,obj)
  }
}
