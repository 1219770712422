import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PendingApprovalModel } from 'src/app/model/menu/mytimesheet/pending-approval-model.model';
import { CommonConstants } from 'src/app/utility/common-constants';

@Injectable({
  providedIn: 'root'
})
export class PendingApprovalService {
  
  strUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.pendingApproval;
 public convertConsultantId:any;

  constructor(private http:HttpClient) { }
  getTimesheetRequestedEmployeeList(pendingApproval:PendingApprovalModel){
      return this.http.post(this.strUrl,pendingApproval);
  }
  getTimesheetRequestedEmployeeListById(pendingApproval:PendingApprovalModel){
    return this.http.post(this.strUrl+'/empid',pendingApproval);
  }
  approveRequestedTimeSheet(pendingApproval:PendingApprovalModel){
    debugger;
    // this.convertConsultantId=pendingApproval.CONSULTANTID;
    // //To avoid key value pair
    // pendingApproval.CONSULTANTID= this.convertConsultantId.CONSULTANTID;
    return this.http.post(this.strUrl+'/approve',pendingApproval);
  }

getUserByConsultantId(obj){
    return this.http.post(this.strUrl+'/getUserByConsultantId',obj);
  }
}
