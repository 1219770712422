import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';
import { MailAlert } from 'src/app/model/menu/admin/mail-alert.model';


const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};
@Injectable({
  providedIn: 'root'
})
export class MailAlertService {

  strUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.mailAlert;
  constructor(private http:HttpClient) { 

  }
  loadSubGroupCompanyName(mailAlert:MailAlert){
    return this.http.post(this.strUrl+'/loadsubgroup',mailAlert);
  }
  getAllEmpMailAlertList(mailAlert:MailAlert){
    return this.http.post(this.strUrl ,mailAlert);
  }
  createEmpMailAlert(mailAlert:MailAlert) {
    return this.http.post(this.strUrl+'/'+CommonConstants.add, mailAlert);
  }

  updateEmpMailAlert(mailAlert:MailAlert) {
     return this.http.post(this.strUrl+'/'+CommonConstants.edit+ '/' + mailAlert.alertid, mailAlert,httpOptions);
   }
   deleteEmpMailAlert(mailAlert:MailAlert){
    return this.http.post(this.strUrl+'/'+CommonConstants.delete+'/'+mailAlert.alertid,mailAlert);
  }
}
