export class MailAlert {
    alertid:number;
    alertname: string;
    subject:string;
    body:string;
    query:string;
    fromid:string;
    ccid:string;
    toid:string;
    currentweek:number;
    status:string='Y';
    subgrpentityname:string;
    grpentityid:number=Number(sessionStorage.getItem("entityId"));
    subgrpentityid:number;
}
