/*export class User {

  id: number;
  firstName: string;
  lastName: string;
  email: string;
}
*/

export class Employee{
  
  CONSULTANTID:string;
  CLIENTNAME:string;
  CLIENTTYPEID:number;
  CLIENTINDUSTRYTYPEID:number;
  ISDIRECTCLIENT:string;
  PAYMENTTERMSCODE:number;
  ACTIVEINACTIVEFLAG:string;
  Notes:string;
  CREATEDUSERID="admin";
  CREATEDDT=new Date();
  MODIFIEDUSERID="admin";
  MODIFIEDDT=new Date();
  STATUS:string;
  


}