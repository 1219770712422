import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';
import { EmployeeVacationDetails } from 'src/app/model/menu/marketing/employee-vacation-details.model';


@Injectable({
  providedIn: 'root'
})
export class CertificationService {
 
  strUrl:string=CommonConstants.baseUrl+'/marketing/certification';
  constructor(private http:HttpClient) { }

  loadSkillNature(obj){
      
    return this.http.post(this.strUrl+'/loadSkillNature',obj)
  }

  loadSkillNatureType(obj){
    return this.http.post(this.strUrl+'/loadSkillNatureType',obj)
  }

  loadSkillDescription(obj){
    return this.http.post(this.strUrl+'/loadSkillNatureDesc',obj)
  }

  save(obj){
    return this.http.post(this.strUrl+'/save',obj)
  }

  edit(obj){
    return this.http.post(this.strUrl+'/edit',obj)
  }

  getMaxCertid(obj){
    return this.http.post(this.strUrl+'/getMaxCertid',obj)
  }

  loadGrid(obj){
    return this.http.post(this.strUrl+'/loadGrid',obj)
  }
 
}
