import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ProjectType } from 'src/app/model/menu/masters/projectType.model';
import { CommonConstants } from 'src/app/utility/common-constants';

const httpOptions = {
    headers: new HttpHeaders({ 
      'Access-Control-Allow-Origin':'*',
      'Authorization':'authkey',
      'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
    })
  };

  @Injectable({
    providedIn: 'root'
  }) 
  export class ProjectTypeService extends BehaviorSubject<any[]>{
   baseUrl: string = CommonConstants.baseUrl+'/'+CommonConstants.projectType;
  values :any;
  constructor(private http: HttpClient) { 
    super([]);
  }
  getProjectTypeDetails(pType){
    return this.http.post(this.baseUrl,pType);
  }

  createProjectType(projectType:ProjectType){
    return this.http.post(this.baseUrl+'/'+CommonConstants.add, projectType);
  }

  updateProjectType(projectType:ProjectType){
    return this.http.post(this.baseUrl + '/'+CommonConstants.edit+'/' + projectType.PROJECTTYPECODE, projectType,httpOptions);
  }

  deleteProjectType(projectType:ProjectType) {
     return this.http.post(this.baseUrl+'/'+CommonConstants.delete,projectType,httpOptions);
  }
  
}
