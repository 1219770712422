import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { EmploymentStatus } from 'src/app/model/menu/masters/employment-status.model';
import { CommonConstants } from 'src/app/utility/common-constants';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};

@Injectable({
  providedIn: 'root'
} )
export class EmploymentStatusService extends BehaviorSubject<any[]>{
  
  baseUrl: string = CommonConstants.baseUrl+'/'+CommonConstants.employmentStatus;
  values :any;
  constructor(private http: HttpClient) { 
    super([]);
  }
  getEmploymentStatusDetails(empStatus){
      return this.http.post(this.baseUrl,empStatus);
  }

  createEmploymentStatus(employmentStatus:EmploymentStatus){
    return this.http.post(this.baseUrl+'/'+CommonConstants.add, employmentStatus);
  }
 
  updateEmploymentStatus(employmentStatus:EmploymentStatus){
    return this.http.post(this.baseUrl + '/'+CommonConstants.edit+'/' + employmentStatus.EmployementStatusid, employmentStatus,httpOptions);
  }

  deleteEmploymentStatus(employmentStatus:EmploymentStatus){
    return this.http.post(this.baseUrl+'/'+CommonConstants.delete,employmentStatus,httpOptions);
  }

  // CheckDuplicateEmploymentStatus(employmentStatus:EmploymentStatus):Observable<String>{
  //   return this.http.get<String>(this.baseUrl + '/' + employmentStatus.EmployementStatusDescription)
  // }
 
}
