export class Certification {
    CONSULTANTID:string;
    CERTID:number;
    SKILLNATUREID:number;
    SKILLNATURETYPEID:number;
    SKILLDESCID:number;
    CERTIFICATIONNAME:string;
    ISSUEDBY:string;
    ISSUEDON:Date;
    EXPIRESON:Date;
    CREATEDUSERID=sessionStorage.getItem("logInuser"); 
    MODIFIEDUSERID=sessionStorage.getItem("logInuser");
    CREATEDDT:any;
    MODIFIEDDT:any;
    grpentityid:number=Number(sessionStorage.getItem("entityId"));
    subgrpentityid:number;
}
