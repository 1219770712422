export class HrRules {
    RULEGROUPID:number;
    RULEID:number;
    RULENAME:string;
    ACTIVEFLAG:any;
    RELATEDEMPFLAG:any;
    RULETYPE:string;
    SUBJECT:string;
    BODY:string;
    UPLOADEDFILEPATH:string;
    TOADDRESS:string;
    grpentityid:number=Number(sessionStorage.getItem("entityId"));
    subgrpentityid:number;
    CREATEDUSERID=sessionStorage.getItem("logInuser"); 
    MODIFIEDUSERID=sessionStorage.getItem("logInuser");
    CREATEDDT:any;
    MODIFIEDDT:any;
}
